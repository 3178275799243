import BillingGroupModel      from 'Models/invoice/BillingGroupModel';
import BillingPeriodModel     from 'Models/invoice/BillingPeriodModel';
import DepositStatusModel     from 'Models/invoice/DepositStatusModel';
import doc                    from 'decorators/doc';
import model                  from 'decorators/model';
import { Timestampable }      from 'helpers/traits';
import { Blamable }           from 'helpers/traits';
import { computed }           from 'mobx';
import InvoicePrivateApiModel from 'modelx/models/abstracts/InvoicePrivateApiModel';

@model.staticLabel('Acompte')
@model.urnResource('deposit')
@doc.path('/deposits/{?id}')
export default class DepositModel extends Blamable(Timestampable(InvoicePrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'amount[between]': string;
		'amount[gt]': string;
		'amount[gte]': string;
		'amount[lt]': string;
		'amount[lte]': string;
		'billingGroup': id;
		'billingGroup.invoicingGroup.owner': id;
		'billingGroup.invoicingGroup.owner.clientUrn': string;
		'billingGroup.invoicingGroup.owner.contactUrn': string;
		'billingGroup.invoicingGroup.owner.generalClientUrn': string;
		'billingGroup.invoicingGroup.owner.partitionUrn': string;
		'billingPeriod': id;
		'billingPeriod.billingPeriodStatus': id;
		'billingPeriod.billingPeriodStatus.reference': BillingPeriodStatusReference;
		'date[after]': string | Date;
		'date[before]': string | Date;
		'date[between]': string | Date;
		'date[strictly_after]': string | Date;
		'date[strictly_before]': string | Date;
		'depositPayments.payment': id;
		'depositStatus': id;
		'depositStatus.reference': InvoiceDepositStatusReference;
		'remainingAmount': number;
		'remainingAmount[between]': string;
		'remainingAmount[gt]': string;
		'remainingAmount[gte]': string;
		'remainingAmount[lt]': string;
		'remainingAmount[lte]': string;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'amount': string;
		'date': string;
		'id': string;
		'remainingAmount': string;
	}> = {};

	@doc.number declare amount: number;
	@doc.model(BillingGroupModel) declare billingGroup: BillingGroupModel;
	@doc.model(BillingPeriodModel) declare billingPeriod: BillingPeriodModel;
	@doc.date declare date: Moment;
	@doc.model(DepositStatusModel) declare depositStatus: DepositStatusModel;
	@doc.string declare reference: InvoiceDepositReference;
	@doc.number declare remainingAmount: number;

	@computed
	public get allocatedAmount() {
		return this.amount - this.remainingAmount;
	}

	@computed
	public get sourceUrn() {
		return this.get('sourceUrn');
	}

	@computed
	public get title() {
		return `N° ${this.id}`;
	}
}